/*html,*/
/*body {*/
/*    height: 100%;*/
/*}*/

/*body {*/
/*    -ms-flex-align: center;;*/
/*    padding-bottom: 40px;*/
/*    background-color: #f5f5f5;*/
/*}*/

.prvy:not(:first-of-type) {
    margin-top: 10px;
}

.jumbotron {
    -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    -webkit-border-radius: 0.125rem;
    border-radius: 0.125rem;
    background-color: #fff;
}

/*.parent {*/
/*    display: flex;*/
/*    flex-wrap: wrap;*/
/*}*/

.child {
    flex: 1 0 21%; /* explanation below */
    margin: 5px;
    max-height: 260px;
}

.blur-body {
    -webkit-filter: blur(5px);
    filter: blur(5px);
}

.tooltip {
    pointer-events: none;
}

input:disabled,
button:disabled {
    cursor: not-allowed;
}

/*.modal.modal-fullscreen .modal-dialog {*/
/*    width: 100vw;*/
/*    height: 100vh;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    max-width: none;*/
/*}*/

/*.modal.modal-fullscreen .modal-content {*/
/*    height: auto;*/
/*    height: 100vh;*/
/*    border-radius: 0;*/
/*    border: none;*/
/*}*/

/*.modal.modal-fullscreen .modal-body {*/
/*    overflow-y: auto;*/
/*}*/

/*.nav-tabs .nav-link.active {*/
/*    background-color: dodgerblue;*/
/*    border-bottom: dodgerblue;*/
/*    font-weight: bold;*/
/*    color: white;*/
/*}*/

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

#sidebar-modal {
    width: 250px;
    position: fixed;
    /*top: 0;*/
    left: 0;
    height: 100vh;
    z-index: 999;
    /*background: #7386D5;*/
    /*color: #fff;*/
    transition: all 0.3s;
}

#sidebar-modal.active {
    margin-left: -250px;
}

.modal-footer.active {
    margin-left: 250px;
    transition: all 0.3s;
}

.modal-footer {
    transition: all 0.3s;
}


/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content-modal {
    width: calc(100% - 250px);
    padding: 5px;
    min-height: 100vh;
    transition: all 0.3s;
    position: absolute;
    top: 0;
    right: 0;
    overflow-x: hidden;
}

#content-modal.active {
    width: 100%;
}

#buttons .dt-buttons {
    margin-bottom: 0;
    padding-top: 0;
}

#buttons_zoznam .dt-buttons {
    margin-bottom: 0;
    padding-top: 0;
}

.wrap-td td {
    max-width: 120px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
}

.wrap-td td:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
}

/*.sticky-bottom-bg {*/
/*    background-image: url('../img/logit_background.png');*/
/*    background-repeat: no-repeat;*/
/*    background-attachment: fixed;*/
/*    background-position: right bottom;*/
/*    !*background-size: cover;*!*/
/*}*/

/*******************
Preloader
********************/
.preloader {
    width: 100%;
    height: 100%;
    top: 0px;
    position: fixed;
    z-index: 99999;
    background: #1d1e23;
}

.lds-ripple {
    display: inline-block;
    width: 64px;
    height: 64px;
    position: absolute;
    top: calc(50% - 3.5px);
    left: 0;
    right: 0;
    margin: 0 auto;
}


svg.tea {
    --secondary: #33406f;
}

svg.tea #teabag {
    transform-origin: top center;
    transform: rotate(3deg);
    animation: swing 2s infinite;
}

svg.tea #steamL {
    stroke-dasharray: 13;
    stroke-dashoffset: 13;
    animation: steamLarge 2s infinite;
}

svg.tea #steamR {
    stroke-dasharray: 9;
    stroke-dashoffset: 9;
    animation: steamSmall 2s infinite;
}

@-moz-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-webkit-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-o-keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@keyframes swing {
    50% {
        transform: rotate(-3deg);
    }
}

@-moz-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }
    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-webkit-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }
    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-o-keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }
    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@keyframes steamLarge {
    0% {
        stroke-dashoffset: 13;
        opacity: 0.6;
    }
    100% {
        stroke-dashoffset: 39;
        opacity: 0;
    }
}

@-moz-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }
    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@-webkit-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }
    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@-o-keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }
    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

@keyframes steamSmall {
    10% {
        stroke-dashoffset: 9;
        opacity: 0.6;
    }
    80% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
    100% {
        stroke-dashoffset: 27;
        opacity: 0;
    }
}

/*.dataTables_scrollHeadInner {*/
/*    width: 100% !important;*/
/*}*/
/*.dataTables_scrollHeadInner table {*/
/*    width: 100% !important;*/
/*}*/

.spinner {
    display: inline-block;
    opacity: 0;
    width: 0;
    -webkit-transition: opacity 0.25s, width 0.25s;
    -moz-transition: opacity 0.25s, width 0.25s;
    -o-transition: opacity 0.25s, width 0.25s;
    transition: opacity 0.25s, width 0.25s;
}

.has-spinner.active {
    cursor: progress;
}

.has-spinner.active .spinner {
    opacity: 1;
    width: auto;
}

.has-spinner.btn.active .spinner {
    min-width: 20px;
}

/* iCheck plugin Line skin, blue
----------------------------------- */
.icheckbox_line-blue,
.iradio_line-blue {
    position: relative;
    display: block;
    margin: 0;
    padding: 5px 15px 5px 38px;
    font-size: 13px;
    line-height: 17px;
    color: #fff;
    background: #2489c5;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
}

.icheckbox_line-blue .icheck_line-icon,
.iradio_line-blue .icheck_line-icon {
    position: absolute;
    top: 50%;
    left: 13px;
    width: 13px;
    height: 11px;
    margin: -5px 0 0 0;
    padding: 0;
    overflow: hidden;
    /*background: url(line.png) no-repeat;*/
    border: none;
}

.icheckbox_line-blue.hover,
.icheckbox_line-blue.checked.hover,
.iradio_line-blue.hover {
    background: #3DA0DB;
}

.icheckbox_line-blue.checked,
.iradio_line-blue.checked {
    background: #2489c5;
}

.icheckbox_line-blue.checked .icheck_line-icon,
.iradio_line-blue.checked .icheck_line-icon {
    background-position: -15px 0;
}

.icheckbox_line-blue.disabled,
.iradio_line-blue.disabled {
    background: #ADD7F0;
    cursor: default;
}

.icheckbox_line-blue.disabled .icheck_line-icon,
.iradio_line-blue.disabled .icheck_line-icon {
    background-position: -30px 0;
}

.icheckbox_line-blue.checked.disabled,
.iradio_line-blue.checked.disabled {
    background: #ADD7F0;
}

.icheckbox_line-blue.checked.disabled .icheck_line-icon,
.iradio_line-blue.checked.disabled .icheck_line-icon {
    background-position: -45px 0;
}

.icheckbox_line-blue-2x,
.iradio_line-blue-2x {
    position: relative;
    display: block;
    margin: 10px;
    padding: 15px 10px 15px 38px;
    font-size: 33px;
    font-weight: bold;
    line-height: 30px;
    color: #fff;
    background: #2489c5;
    border: none;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    cursor: pointer;
}

.icheckbox_line-blue-2x .icheck_line-icon,
.iradio_line-blue-2x .icheck_line-icon {
    position: absolute;
    top: 50%;
    left: 13px;
    width: 13px;
    height: 11px;
    margin: -5px 0 0 0;
    padding: 0;
    overflow: hidden;
    /*background: url(line.png) no-repeat;*/
    border: none;
}

.icheckbox_line-blue-2x.hover,
.icheckbox_line-blue-2x.checked.hover,
.iradio_line-blue-2x.hover {
    background: #3DA0DB;
}

.icheckbox_line-blue-2x.checked,
.iradio_line-blue-2x.checked {
    background: #2aa523;
}

.icheckbox_line-blue-2x.checked .icheck_line-icon,
.iradio_line-blue-2x.checked .icheck_line-icon {
    background-position: -15px 0;
}

.icheckbox_line-blue-2x.disabled,
.iradio_line-blue-2x.disabled {
    background: #ADD7F0;
    cursor: default;
}

.icheckbox_line-blue-2x.disabled .icheck_line-icon,
.iradio_line-blue-2x.disabled .icheck_line-icon {
    background-position: -30px 0;
}

.icheckbox_line-blue-2x.checked.disabled,
.iradio_line-blue-2x.checked.disabled {
    background: #ADD7F0;
}

.icheckbox_line-blue-2x.checked.disabled .icheck_line-icon,
.iradio_line-blue-2x.checked.disabled .icheck_line-icon {
    background-position: -45px 0;
}

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_line-blue .icheck_line-icon,
    .iradio_line-blue .icheck_line-icon {
        /*background-image: url(line@2x.png);*/
        -webkit-background-size: 60px 13px;
        background-size: 60px 13px;
    }

    .icheckbox_line-blue-2x .icheck_line-icon,
    .iradio_line-blue-2x .icheck_line-icon {
        /*background-image: url(line@2x.png);*/
        -webkit-background-size: 120px 13px;
        background-size: 120px 13px;
    }
}

.dropzone {
    border: 2px dashed #dedede !important;
    border-radius: 5px !important;
}

.dropzone i {
    font-size: 5rem !important;
}

.dropzone .dz-message {
    font-weight: 500 !important;
    font-size: initial !important;
    text-transform: uppercase !important;
}

.btn {
    text-transform: unset !important;
}

.select2-container--bootstrap-5 .select2-dropdown {
    z-index: 1066 !important;
}

/*Jquery-idleTimerPlus*/
.jitp-lock-back {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #e9e9e9;
    cursor: no-drop;
    text-align: center;
}

.jitp-lock-panel {
    position: absolute;
    width: 40%;
    max-width: 400px;

    top: 50%;
    left: 50%;
    /*margin-top: -130px;*/
    /*margin-left: -180px;*/
    transform: translate(-50%, -50%);

    cursor: auto;
}

#jitp-lock-logout {
    display: block;
    padding-top: 8px;
}

.jitp-lock-panel .jitp-countdown-holder {
    font-weight: bold;
}

.jitp-lock-panel .panel-footer {
    font-size: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.jitp-lock-jqpanel {
    background-color: white;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
    font-size: 16px;
    font-family: lato, serif;
    position: relative;
    color: #333;
    border-radius: 10px;
}

.jitp-lock-jqpanel header {
    background: #ED2553;
    padding: 15px 20px;
    color: white;
    font-size: 1.5em;
    font-weight: 600;
    border-radius: 10px 10px 0 0;
}

.jitp-lock-jqpanel #jitp-lock-pass {
    font-size: 1em;
}


.jitp-lock-jqpanel button {
    margin-top: 20px;
    margin-bottom: 5px;
    left: 50%;
    font-family: inherit;
    color: white;
    background: #FF3838;
    outline: none;
    border: none;
    padding: 5px 15px;
    font-size: 1.3em;
    font-weight: 400;
    border-radius: 3px;
    box-shadow: 0 0 10px rgba(51, 51, 51, 0.4);
    cursor: pointer;
    transition: all 0.15s ease-in-out;
}

.jitp-lock-jqpanel #jitp-lock-logout {
    padding-bottom: 8px;
}

.jitp-lock-jqpanel footer {
    background: #ED2553;
    padding: 8px 20px;
    color: white;
    border-radius: 0 0 10px 10px;
}


/*TABA Loader*/

.tb-loading__progress-box {
    padding: 0 0 5px;
    display: inline-block;
    width: 100%;
    text-align: center;
    height: 100vh;
    padding-top: 40vh;
    font-family: sans-serif;
}

.tb-loading__spinner {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1010;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-transform: translate3d(0, 0, 0);
    -webkit-backface-visibility: hidden;
    -webkit-perspective: 1000;
    -webkit-transition: 0.2s opacity linear;
    transition: 0.2s opacity linear;
    width: 100%;
    height: 75px;
}

.tb-loading__spinner__animation {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    width: 50px;
    height: 65px;
}

.tb-loading__spinner--progressbar {
    flex-flow: column;
}


.tb-loading__spinner__animation__path-1 {
    animation: path1Keyframes linear 1.67s;
    -webkit-animation: path1Keyframes linear 1.67s;
    -moz-animation: path1Keyframes linear 1.67s;
    -o-animation: path1Keyframes linear 1.67s;
    -ms-animation: path1Keyframes linear 1.67s;
}

.tb-loading__spinner__animation__path-2 {
    animation: path2Keyframes linear 1.67s;
    -webkit-animation: path2Keyframes linear 1.67s;
    -moz-animation: path2Keyframes linear 1.67s;
    -o-animation: path2Keyframes linear 1.67s;
    -ms-animation: path2Keyframes linear 1.67s;
}

.tb-loading__spinner__animation__path-3 {
    animation: path3Keyframes linear 1.67s;
    -webkit-animation: path3Keyframes linear 1.67s;
    -moz-animation: path3Keyframes linear 1.67s;
    -o-animation: path3Keyframes linear 1.67s;
    -ms-animation: path3Keyframes linear 1.67s;
}

.tb-loading__spinner__animation__path-1, .tb-loading__spinner__animation__path-2, .tb-loading__spinner__animation__path-3 {
    position: absolute;
    top: 0;
    left: 0;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -o-animation-iteration-count: infinite;
    -o-transform-origin: 50% 50%;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
}

@keyframes path1Keyframes {
    0% {
        opacity: 1;
        -webkit-transform: scaleX(.918) scaleY(.655);
        transform: scaleX(.918) scaleY(.655);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    10% {
        opacity: 1;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    20% {
        -webkit-transform: scaleX(.839) scaleY(.599);
        transform: scaleX(.839) scaleY(.599);
    }
    30% {
        opacity: 0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    }
    50% {
        opacity: 0;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
        -webkit-transform: scaleX(.8) scaleY(.571);
        transform: scaleX(.8) scaleY(.571);
    }
    70% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
        -webkit-transform: scaleX(.918) scaleY(.655);
        transform: scaleX(.918) scaleY(.655);
    }
    80% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    100% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
        -webkit-transform: scaleX(.918) scaleY(.655);
        transform: scaleX(.918) scaleY(.655);
    }
}

@-webkit-keyframes path1Keyframes {
    0% {
        opacity: 1;
        -webkit-transform: scaleX(.918) scaleY(.655);
        transform: scaleX(.918) scaleY(.655);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    10% {
        opacity: 1;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    20% {
        -webkit-transform: scaleX(.839) scaleY(.599);
        transform: scaleX(.839) scaleY(.599);
    }
    30% {
        opacity: 0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
    }
    50% {
        opacity: 0;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
        -webkit-transform: scaleX(.8) scaleY(.571);
        transform: scaleX(.8) scaleY(.571);
    }
    70% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
        -webkit-transform: scaleX(.918) scaleY(.655);
        transform: scaleX(.918) scaleY(.655);
    }
    80% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    100% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
        -webkit-transform: scaleX(.918) scaleY(.655);
        transform: scaleX(.918) scaleY(.655);
    }
}


@keyframes path2Keyframes {
    0% {
        opacity: 1;
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    10% {
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    20% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    30% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    40% {
        opacity: 0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        -webkit-transform: scaleX(.97) scaleY(.855);
        transform: scaleX(.97) scaleY(.855);
    }
    60% {
        opacity: 0;
        -webkit-transform: scaleX(.97) scaleY(.855);
        transform: scaleX(.97) scaleY(.855);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    80% {
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    90% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    100% {
        opacity: 1;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
    }
}

@-webkit-keyframes path2Keyframes {
    0% {
        opacity: 1;
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    10% {
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    20% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    30% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    40% {
        opacity: 0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        -webkit-transform: scaleX(.97) scaleY(.855);
        transform: scaleX(.97) scaleY(.855);
    }
    60% {
        opacity: 0;
        -webkit-transform: scaleX(.97) scaleY(.855);
        transform: scaleX(.97) scaleY(.855);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    80% {
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    90% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    100% {
        opacity: 1;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
        -webkit-transform: scaleX(1.095) scaleY(.965);
        transform: scaleX(1.095) scaleY(.965);
    }
}

@keyframes path3Keyframes {
    0% {
        opacity: 1;
        -webkit-transform: scaleX(.683) scaleY(.733);
        transform: scaleX(.683) scaleY(.733);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    20% {
        opacity: 1;
        -webkit-transform: scaleX(.683) scaleY(.733);
        transform: scaleX(.683) scaleY(.733);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    30% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    40% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    50% {
        opacity: 0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        -webkit-transform: scaleX(.55) scaleY(.59);
        transform: scaleX(.55) scaleY(.59);
    }
    70% {
        opacity: 0;
        -webkit-transform: scaleX(.55) scaleY(.59);
        transform: scaleX(.55) scaleY(.59);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    90% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
        -webkit-transform: scaleX(.683) scaleY(.733);
        transform: scaleX(.683) scaleY(.733);
    }
    100% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        -webkit-transform: scaleX(.683) scaleY(.733);
        transform: scaleX(.683) scaleY(.733);
    }
}

@-webkit-keyframes path3Keyframes {
    0% {
        opacity: 1;
        -webkit-transform: scaleX(.683) scaleY(.733);
        transform: scaleX(.683) scaleY(.733);
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    20% {
        opacity: 1;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100), progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    30% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
    }
    40% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    50% {
        opacity: 0;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
        -webkit-transform: scaleX(.55) scaleY(.59);
        transform: scaleX(.55) scaleY(.59);
    }
    70% {
        opacity: 0;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="6" /></filter></svg>#filter');
        -webkit-filter: blur(6px);
        filter: blur(6px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0), progid:DXImageTransform.Microsoft.Blur(pixelradius=6)';
    }
    90% {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
        -webkit-filter: blur(0px);
        filter: blur(0px);
        -ms-filter: 'progid:DXImageTransform.Microsoft.Blur(pixelradius=0)';
    }
    100% {
        opacity: 1;
        -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=100)';
        -webkit-transform: scaleX(.683) scaleY(.733);
        transform: scaleX(.683) scaleY(.733);
    }
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
    color: #000;
}

.daterangepicker .ranges {
  color: #000;
}

/*!*daterangepicker dark*!*/

/*.daterangepicker .calendar-table {*/
/*    background-color: transparent;*/
/*}*/

/*.datepicker.dropdown-menu table  {*/
/*    background-color: transparent;*/
/*}*/
/*.daterangepicker td.active, .daterangepicker td.active:hover {*/
/*    background-color: transparent;*/
/*    color: #ebf4f8;*/
/*}*/
/*.daterangepicker td.in-range {*/
/*    background-color: rgba(37,47,57,0.9);*/
/*    color: #ebf4f8;*/
/*}*/

/*.daterangepicker .calendar-table .prev span {*/
/*    border-color: #fff;*/
/*}*/

/*.daterangepicker .calendar-table .next span {*/
/*    border-color: #fff;*/
/*}*/

/*.btn-success {*/
/*    color: #fff;*/
/*    background-color: inherit;*/
/*    border-color: rgba(5,150,1,0.8);*/
/*}*/
/*.btn-success.disabled, .btn-success[disabled], fieldset[disabled] .btn-success, .btn-success.disabled:hover, .btn-success[disabled]:hover, fieldset[disabled] .btn-success:hover, .btn-success.disabled:focus, .btn-success[disabled]:focus, fieldset[disabled] .btn-success:focus, .btn-success.disabled.focus, .btn-success[disabled].focus, fieldset[disabled] .btn-success.focus, .btn-success.disabled:active, .btn-success[disabled]:active, fieldset[disabled] .btn-success:active, .btn-success.disabled.active, .btn-success[disabled].active, fieldset[disabled] .btn-success.active {*/
/*    color: #fff;*/
/*    background-color: inherit;*/
/*    border-color: rgba(5,150,1,0.1);*/
/*}*/
/*.btn-default {*/
/*    color: #fff;*/
/*    background-color: inherit;*/
/*    border-color: rgba(255,255,255,0.6);*/
/*}*/
/*.daterangepicker select.monthselect {*/
/*    color: rgba(37,47,57,0.9);*/
/*}*/
/*.daterangepicker select.yearselect {*/
/*    color: rgba(37,47,57,0.9);*/
/*}*/
/*.daterangepicker .input-mini {*/
/*    color: #ebf4f8;*/
/*}*/

/*Datatables Scroller*/

/*div.dts {*/
/*    display: block !important;*/
/*}*/

/*div.dts tbody th,*/
/*div.dts tbody td {*/
/*    white-space: nowrap;*/
/*}*/

/*div.dts div.dts_loading {*/
/*    z-index: 1;*/
/*}*/

/*div.dts div.dts_label {*/
/*    position: absolute;*/
/*    right: 10px;*/
/*    background: rgba(0, 0, 0, 0.8);*/
/*    color: white;*/
/*    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.5);*/
/*    text-align: right;*/
/*    border-radius: 3px;*/
/*    padding: 0.4em;*/
/*    z-index: 2;*/
/*    display: none;*/
/*}*/

/*div.dts div.dataTables_scrollBody table {*/
/*    z-index: 2;*/
/*}*/

/*div.dts div.dataTables_paginate,*/
/*div.dts div.dataTables_length {*/
/*    display: none;*/
/*}*/

/*Datatables fixedHeader*/

table.dataTable.fixedHeader-floating,
table.dataTable.fixedHeader-locked {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

table.dataTable.fixedHeader-locked {
  position: absolute !important;
}

@media print {
  table.fixedHeader-floating {
    display: none;
  }
}

.dark .col-form-label {
    color: white;
}
.form-control:disabled, .DTE_Field_InputControl .form-control[readonly] {
    background-color: transparent !important;
}
.fixedHeader-floating {
    background-color: white !important;
}
.dark .fixedHeader-floating {
    background-color: #2c2d31 !important;
}
