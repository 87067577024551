@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

$font-family-base: Poppins;
$input-disabled-bg: #2c2d31;
$info: #1e88e5 !default;
$tatra-banka: #0e2050;
$gray-600: #6c757d;

@import 'mdb/src/mdb/scss/mdb.pro.scss';

// Create your own map
$custom-colors: (
        "tatra-banka": $tatra-banka,
        "info": $info,
        "secondary": $gray-600
);

// Merge the maps
$theme-colors: map-merge($theme-colors, $custom-colors);


@import 'mdb/src/mdb/scss/mdb.pro.scss';
//@import 'mdb/src/mdb/scss/mdb.dark.scss';
$mdb-theme-dark-background: #1d1e23;
$mdb-theme-dark-surface: #2c2d31;
$mdb-theme-light-background: rgb(180, 173, 173);
$mdb-theme-light-surface: rgba(255, 255, 255, 0.5);
// DARK SKIN
$my-theme-primary: #1266f1;
$my-theme-secondary: #b23cfd;
$my-dark-theme: mdb-dark-theme($my-theme-primary, $my-theme-secondary);
$my-light-theme: mdb-light-theme($my-theme-primary, $my-theme-secondary);
.dark {
  @include mdb-theme($my-dark-theme);
}
//.light {
//  @include mdb-theme($my-light-theme);
//}


//$fa-font-path: '../../node_modules/@fortawesome/fontawesome-free/webfonts';
//@import 'node/@fortawesome/fontawesome-free/scss/fontawesome.scss';
//@import 'node/@fortawesome/fontawesome-free/scss/solid.scss';
//@import 'node/@fortawesome/fontawesome-free/scss/regular.scss';
@import 'node/@sweetalert2/theme-dark/dark.scss';
@import "node/daterangepicker/daterangepicker.css";
//@import "node/noty/src/noty.scss";
//@import "node/noty/src/themes/mint.scss";
//@import "node/select2/src/scss/core.scss";
//@import "node/select2-bootstrap-5-theme/src/select2-bootstrap-5-theme.scss";
@import "node/dropzone/src/dropzone.scss";

@import "../css/main.css";
